/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file
import './scss/frontend.scss';

import { Collapse, Dropdown } from 'bootstrap';

import './js/swiper.js';

import './js/plyr.js';

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

Fancybox.bind('[data-fancybox]');

document.addEventListener('DOMContentLoaded', function () {
  AOS.init();

  const swiper = new Swiper('#homepage-swiper', {
    spaceBetween: 32,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 'auto',
      },
    },
  });
});
